import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  list: params => get('/residentArchives/list', params),
  // 根据id获取人口详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // 根据bizId查询访客照片
  picVisitor: args => get(`/file/getByBiz?bizId=${args}`),
  // 获取省的行政区划
  getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // 获取市级的行政区划
  getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&provinceCode=${provinceCode}`),
  // 获取区级的行政区划
  getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&cityCode=${cityCode}`),
  // 获取县级的行政区划
  getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&countyCode=${countyCode}`),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),
  // 根据小区id查询所有楼栋
  getBulidingManagement: gridId => get(`/scBulidingManagement/queryByGridId?gridId=${gridId}`),
  // 根据楼栋id查询所有单元
  getUnitInfoById: buildingId => get(`/scUnitInfo/queryByBuildId?buildingId=${buildingId}`),
  // 根据单元名称查询所有楼层
  getUnitInfoByUnitName: ({ buildingId, unitName }) => get(`/scUnitInfo/queryByUnitName?unitName=${unitName}&buildingId=${buildingId}`),
  // 获取门牌号
  getUnitInfoByFloorName: (buildingId, unitName, floorName) => get(`/scUnitInfo/queryByFloorName?buildingId=${buildingId}&unitName=${unitName}&floorName=${floorName}`),
  // 获取居民信息表格数据
  getPersonTableData: args => get('/residentArchives/listAll', args),

  /**
   * 插入/修改
   */
  // 新增人口
  addPeople: args => post('/residentArchives/add', args),
  // 根据id删除
  deleteResidentArchivesById: id => deletes(`/residentArchives/deleteById?id=${id}`),
  // 批量删除
  deleteResidentArchivesByIds: ids => deletes(`/residentArchives/ids?ids=${ids}`),
  // 根据id编辑人口信息
  updateResidentArchives: args => post('/residentArchives/update', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 导入
  importExcle: args => post('/residentArchives/importExcel', args),
  // 下载模板
  // 导出
  getTemplate: args => downFile('/residentArchives/getTemplate', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),
  
  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
